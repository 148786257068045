var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cycle-container" },
    [
      _c(
        "a-button",
        {
          staticClass: "edit",
          attrs: { prefix: "xiugaisuanxinrenyuan18" },
          on: {
            click: function ($event) {
              return _vm.openBtn($event)
            },
          },
        },
        [_vm._v("修改")]
      ),
      _c("p", [_vm._v("计薪周期：" + _vm._s(_vm.dateText) + " ")]),
      _c("p", [_vm._v("生效月份：" + _vm._s(_vm.effectiveYearMonth) + " ")]),
      _c("RevisePayTime", {
        ref: "revisePayTime",
        attrs: {
          "time-list": _vm.timeList,
          "next-time-list": _vm.nextTimeList,
        },
        on: { refresh: _vm.refresh },
      }),
      _c("Table", { ref: "table" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }