// 薪酬-计薪周期
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 获取计薪周期月类型
export async function getBaseCode(params) {
  return request(`${WAGE}/payPeroid/getBaseCode`, METHOD.GET, params)
}

// 获取计薪周期配置
export async function getConfig(params) {
  return request(`${WAGE}/payPeroid/getConfig`, METHOD.GET, params)
}

// 设置计薪周期配置
export async function setConfig(params) {
  return request(`${WAGE}/payPeroid/setConfig`, METHOD.POST, params)
}

