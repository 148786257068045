var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "regional-class-container" },
    [
      _vm._l(_vm.areaLevelList, function (item) {
        return _c("div", { key: item.level }, [
          _c(
            "div",
            { staticClass: "level-header flex-align-center" },
            [
              _c(
                "BlockTitle",
                {
                  attrs: {
                    title: _vm._f("formatLevel")(item.level),
                    "is-bold": true,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "left" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addLevelArea(item)
                            },
                          },
                        },
                        [_vm._v("添加城市")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "level-content" },
            _vm._l(item.areaList, function (area) {
              return _c("span", { key: area.areaCode, staticClass: "area" }, [
                _vm._v(_vm._s(area.areaName)),
              ])
            }),
            0
          ),
        ])
      }),
      _c("EditAreaDrawer", {
        ref: "editArea",
        on: { refresh: _vm.getAreaLevelList },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }