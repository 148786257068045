var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pay-slip-container" }, [
    _c("div", { staticClass: "pay-slip-main" }, [
      _c("div", { staticClass: "left-setting" }, [
        _c("div", { staticClass: "pay-slip-common" }, [
          _c("div", { staticClass: "pay-slip-common-text" }, [
            _vm._v("温馨提示"),
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "switch-icon" },
              [
                _c("a-switch", {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.tipsSwitch,
                    callback: function ($$v) {
                      _vm.tipsSwitch = $$v
                    },
                    expression: "tipsSwitch",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tipsSwitch,
                    expression: "tipsSwitch",
                  },
                ],
                staticClass: "textarea-text",
              },
              [
                _c("a-textarea", {
                  attrs: { placeholder: "请输入温馨提示", rows: 5 },
                  model: {
                    value: _vm.tipsContent,
                    callback: function ($$v) {
                      _vm.tipsContent = $$v
                    },
                    expression: "tipsContent",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "pay-slip-common dataNull" }, [
          _c("div", { staticClass: "pay-slip-common-text" }, [
            _vm._v("隐藏空数据项"),
          ]),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "dataNull-first" },
              [
                _c(
                  "a-checkbox",
                  {
                    model: {
                      value: _vm.dateNull,
                      callback: function ($$v) {
                        _vm.dateNull = $$v
                      },
                      expression: "dateNull",
                    },
                  },
                  [
                    _c("span", { staticClass: "dataNull-span" }, [
                      _vm._v("薪资项为空时，隐藏该项"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    model: {
                      value: _vm.dateZero,
                      callback: function ($$v) {
                        _vm.dateZero = $$v
                      },
                      expression: "dateZero",
                    },
                  },
                  [
                    _c("span", { staticClass: "dataNull-span" }, [
                      _vm._v("薪资项数额为0时，隐藏该项"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "pay-slip-common" }, [
          _c("div", { staticClass: "pay-slip-common-text" }, [
            _vm._v("工资条查看密码"),
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "switch-icon" },
              [
                _c("a-switch", {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.passwordSwitch,
                    callback: function ($$v) {
                      _vm.passwordSwitch = $$v
                    },
                    expression: "passwordSwitch",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "pay-slip-common" }, [
          _vm._m(0),
          _c("div", [
            _c(
              "div",
              { staticClass: "switch-icon slip-icon" },
              [
                _c("a-switch", {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.enterSwitch,
                    callback: function ($$v) {
                      _vm.enterSwitch = $$v
                    },
                    expression: "enterSwitch",
                  },
                }),
              ],
              1
            ),
            _vm.enterSwitch
              ? _c(
                  "div",
                  { staticClass: "textarea-text" },
                  [
                    _c(
                      "a-radio-group",
                      {
                        staticClass: "radio-group",
                        model: {
                          value: _vm.enterType,
                          callback: function ($$v) {
                            _vm.enterType = $$v
                          },
                          expression: "enterType",
                        },
                      },
                      [
                        _c(
                          "a-radio",
                          { staticClass: "group-text", attrs: { value: 1 } },
                          [_vm._v(" 不自动确认 ")]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-radio",
                              { attrs: { value: 2 } },
                              [
                                _c("CbNumber", {
                                  attrs: { min: 1, max: 100000 },
                                  model: {
                                    value: _vm.enterTime,
                                    callback: function ($$v) {
                                      _vm.enterTime = $$v
                                    },
                                    expression: "enterTime",
                                  },
                                }),
                                _c("span", { staticClass: "group-text" }, [
                                  _vm._v(" 天自动确认 "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "pay-slip-common readAfter" }, [
          _c("div", { staticClass: "pay-slip-common-text" }, [
            _vm._v("阅后即焚"),
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "switch-icon slip-icon" },
              [
                _c("a-switch", {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.snapchatSwitch,
                    callback: function ($$v) {
                      _vm.snapchatSwitch = $$v
                    },
                    expression: "snapchatSwitch",
                  },
                }),
              ],
              1
            ),
            _vm.snapchatSwitch
              ? _c(
                  "div",
                  { staticClass: "textarea-text" },
                  [
                    _c("span", { staticClass: "slip-check" }, [
                      _vm._v("员工查看"),
                    ]),
                    _c("CbNumber", {
                      staticClass: "number-check",
                      attrs: { min: 1, max: 100000 },
                      model: {
                        value: _vm.snapchatNumber,
                        callback: function ($$v) {
                          _vm.snapchatNumber = $$v
                        },
                        expression: "snapchatNumber",
                      },
                    }),
                    _c(
                      "a-select",
                      {
                        attrs: { "default-value": "小时" },
                        model: {
                          value: _vm.snapchatUnit,
                          callback: function ($$v) {
                            _vm.snapchatUnit = $$v
                          },
                          expression: "snapchatUnit",
                        },
                      },
                      _vm._l(_vm.timeList, function (item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    ),
                    _c("span", { staticClass: "slip-destroy" }, [
                      _vm._v("后自动销毁"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "pay-slip-common proview" }, [
          _c("div", { staticClass: "pay-slip-common-text" }, [
            _vm._v("工资条预览"),
          ]),
          _c(
            "div",
            { staticClass: "salary-groups" },
            _vm._l(_vm.salaryGroups, function (item) {
              return _c(
                "div",
                {
                  key: item.groupId,
                  staticClass: "salary-item",
                  class: { active: item.groupId === _vm.salaryItem.groupId },
                  on: {
                    click: function ($event) {
                      return _vm.selectProview(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.groupName) + " ")]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "rigth-phone" },
        [
          _c("proviewMin", {
            attrs: {
              "tips-switch": _vm.tipsSwitch,
              "tips-content": _vm.tipsContent,
              "salary-list": _vm.salaryList,
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "screen_btn" },
      [
        _c(
          "a-button",
          { attrs: { type: "primary" }, on: { click: _vm.setConfig } },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pay-slip-common-text" }, [
      _c("span", [_vm._v("工资条确认时间")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }