var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "proview-min" }, [
    _vm._m(0),
    _c("div", { staticClass: "screen-view" }, [
      _c("div", { staticClass: "head-view" }, [
        _c("div", { staticClass: "head-title" }, [_vm._v("工资条")]),
        _c("div", { staticClass: "head-centent" }, [_vm._v(_vm._s(_vm.time))]),
        _vm._m(1),
      ]),
      _vm.tipsSwitch
        ? _c("div", { staticClass: "tips-view" }, [
            _c("div", { staticClass: "tip-text" }, [
              _vm._v(" 温馨提示： " + _vm._s(_vm.tipsContent) + " "),
            ]),
          ])
        : _vm._e(),
      _vm.salaryList.length !== 0
        ? _c(
            "div",
            { staticClass: "salary-list" },
            _vm._l(_vm.salaryList, function (item, index) {
              return _c("div", { key: index, staticClass: "salary-item" }, [
                _c("div", { staticClass: "salary-title" }, [
                  _vm._v(_vm._s(item.className)),
                ]),
                _c(
                  "div",
                  { staticClass: "salary-outer" },
                  _vm._l(item.list, function (salary, sindex) {
                    return _c(
                      "div",
                      { key: sindex + "_" + index, staticClass: "salary-text" },
                      [
                        _c("div", { staticClass: "salary-label" }, [
                          _vm._v(_vm._s(salary.salaryitemname)),
                        ]),
                        _c("div", { staticClass: "salary-value" }, [
                          _vm._v("888"),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          )
        : _c("div", { staticClass: "nodata" }, [_vm._v(" 暂无数据~ ")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "receiver" }, [
      _c("div", { staticClass: "long-view" }),
      _c("div", { staticClass: "short-view" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head-main" }, [
      _c("div", { staticClass: "main-item" }, [
        _c("div", { staticClass: "main-item-num" }, [_vm._v("8888.88")]),
        _c("div", { staticClass: "main-item-label" }, [_vm._v("实发工资(元)")]),
      ]),
      _c("div", { staticClass: "main-item" }, [
        _c("div", { staticClass: "main-item-num" }, [_vm._v("88.88")]),
        _c("div", { staticClass: "main-item-label" }, [
          _vm._v("个人所得税(元)"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }