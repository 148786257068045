// 薪酬-设置-地区分级
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 获取地区分级
export async function getAreaLevelList(params) {
  return request(`${WAGE}/areaLevel/getAreaLevelList`, METHOD.GET, params)
}

// 配置分级地区
export async function setByLevel(params) {
  return request(`${WAGE}/areaLevel/setByLevel`, METHOD.POST, params)
}

