// 工资条模版设置
// 工资条
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 设置工资条模板配置
export async function setConfig(params) {
  return request(`${WAGE}/salarySheetTemplate/setConfig`, METHOD.POST, params)
}

// 获取工资条模板配置
export async function getConfig(params) {
  return request(`${WAGE}/salarySheetTemplate/getConfig`, METHOD.GET, params)
}
// 获取预览的数据
export async function getSalaryGroupAndItem(params) {
  return request(`${WAGE}/salarySheetTemplate/getSalaryGroupAndItem`, METHOD.GET, params)
}
