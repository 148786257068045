var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PayAccount" },
    [
      _c(
        "div",
        [
          _c(
            "appForm",
            [
              _c("template", { slot: "customButton" }, [
                _c(
                  "div",
                  { staticClass: "custom-button" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { prefix: "piliangdaoru" },
                        on: { click: _vm.addGroup },
                      },
                      [_vm._v(" 新增报税主体 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("custom-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataSource,
              "row-key": "index",
              pagination: false,
              scroll: { x: 1220 },
            },
            on: { change: _vm.loadDataList },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c(
                      "span",
                      { staticClass: "operation-btn-box" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.record)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "a-button",
                          {
                            staticClass: "delete",
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.delet(scope.record)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("TaxDrawer", {
        ref: "taxDrawer",
        attrs: { "drawer-type": _vm.drawerType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }