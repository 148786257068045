// 薪酬-设置-消息设置
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 获取调薪消息提醒配置
export async function getSalaryAdjustmentConfig(params) {
  return request(`${WAGE}/notifyTemplate/getSalaryAdjustmentConfig`, METHOD.GET, params)
}

// 设置调薪消息提醒配置
export async function setSalaryAdjustmentConfig(params) {
  return request(`${WAGE}/notifyTemplate/setSalaryAdjustmentConfig`, METHOD.POST, params)
}

// 获取工资条配置
export async function getSalaryTemplateRemindConfig(params) {
  return request(`${WAGE}/salarySheetTemplate/getSalaryTemplateRemindConfig`, METHOD.GET, params)
}

// 设置调薪消息提醒配置
export async function setSalaryTemplateRemindConfig(params) {
  return request(`${WAGE}/salarySheetTemplate/setSalaryTemplateRemindConfig`, METHOD.POST, params)
}

