var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "salary-group-container" },
    [
      _vm.groupVisible
        ? _c(
            "div",
            { staticClass: "salary-group-table" },
            [
              _c(
                "div",
                { staticClass: "add-salary" },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "ant-button",
                      attrs: { prefix: "add" },
                      on: { click: _vm.addGroup },
                    },
                    [_vm._v(" 新增薪资组 ")]
                  ),
                ],
                1
              ),
              _c("custom-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.dataSource,
                  "row-key": "itemNo",
                  pagination: false,
                },
                on: { change: _vm.loadDataList },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "userCount",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _vm._v(" " + _vm._s(scope.record.userCount) + "人 "),
                        ]
                      },
                    },
                    {
                      key: "enabled",
                      fn: function (ref) {
                        var scope = ref.scope
                        return _c(
                          "span",
                          {
                            staticClass: "label",
                            class:
                              scope.record.enabled == 1 ? "enable" : "close",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.record.enabled == 1 ? "启用" : "关闭"
                                ) +
                                " "
                            ),
                          ]
                        )
                      },
                    },
                    {
                      key: "operation",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c(
                            "span",
                            { staticClass: "pre-operation-box" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editGroup(scope.record)
                                    },
                                  },
                                },
                                [_vm._v(" 编辑 ")]
                              ),
                              _c(
                                "a-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("template", { slot: "content" }, [
                                    _c(
                                      "div",
                                      { staticClass: "more-handle-btn" },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { type: "link" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyGroup(
                                                  scope.record
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 复制 ")]
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "delete",
                                            attrs: { type: "link" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deletGroup(
                                                  scope.record
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                        !scope.record.enabled
                                          ? _c(
                                              "a-button",
                                              {
                                                attrs: { type: "link" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.emptyPersonnel(
                                                      scope.record
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 清空人员 ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "more-handle" }, [
                                    _vm._v(" ··· "),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2614432258
                ),
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("GroupSteps", {
                attrs: {
                  "group-id": _vm.groupId,
                  "handler-type": _vm.handlerType,
                },
                on: { goBack: _vm.goBack },
              }),
            ],
            1
          ),
      _c("CopyDrawer", {
        ref: "copyDrawer",
        on: { refresh: _vm.serchGetData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }