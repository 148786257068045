var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-setting-container" },
    [
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "infoId",
          pagination: false,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "remindTime",
            fn: function (ref) {
              var record = ref.scope.record
              return [
                record.remindTimeType === "2"
                  ? _c("span", [
                      _vm._v(
                        "定时提醒，" +
                          _vm._s(
                            record.messageCategory === "调薪提醒"
                              ? "调薪完成"
                              : "工资条发送"
                          ) +
                          _vm._s(record.remindTime) +
                          _vm._s(
                            record.remindTimeUnit === "1" ? "小时" : "天"
                          ) +
                          "后提醒"
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          record.messageCategory === "调薪提醒"
                            ? "完成调薪操作"
                            : "工资条发送"
                        ) + "立即提醒"
                      ),
                    ]),
              ]
            },
          },
          {
            key: "remindNotifiler",
            fn: function (ref) {
              var record = ref.scope.record
              return [
                record.remindNotifiler !== "3"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.notifierList[record.remindNotifiler - 1].person
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(" " + _vm._s(_vm.notifierListMapText) + " "),
                    ]),
              ]
            },
          },
          {
            key: "remindEnableSwitch",
            fn: function (ref) {
              var record = ref.scope.record
              return _c(
                "span",
                {},
                [
                  _c("a-switch", {
                    attrs: {
                      size: "small",
                      "checked-children": "启用",
                      "un-checked-children": "禁用",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.switchChange(record)
                      },
                    },
                    model: {
                      value: record.remindEnableSwitch,
                      callback: function ($$v) {
                        _vm.$set(record, "remindEnableSwitch", $$v)
                      },
                      expression: "record.remindEnableSwitch",
                    },
                  }),
                ],
                1
              )
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var record = ref.scope.record
              return [
                _c(
                  "div",
                  { staticClass: "more-handle-btn" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(record)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "Drawer",
        {
          attrs: {
            "is-mask": false,
            closable: "",
            visible: _vm.drawer,
            title: "编辑消息提醒",
            placement: "right",
            size: "small",
          },
          on: {
            close: function ($event) {
              _vm.drawer = false
            },
            onSubmit: _vm.onSubmin,
          },
        },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.infoForm,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "消息类别" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.infoForm.messageCategory,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoForm, "messageCategory", $$v)
                      },
                      expression: "infoForm.messageCategory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "提醒时间", prop: "remindTimeType" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { "default-value": _vm.infoForm.remindTimeType },
                      model: {
                        value: _vm.infoForm.remindTimeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.infoForm, "remindTimeType", $$v)
                        },
                        expression: "infoForm.remindTimeType",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.infoForm.messageCategory === "调薪提醒"
                              ? "调薪完成"
                              : "完成工资发放"
                          ) + ",立即提醒"
                        ),
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v("定时提醒"),
                      ]),
                    ],
                    1
                  ),
                  _vm.infoForm.remindTimeType === "2"
                    ? _c(
                        "div",
                        { staticClass: "timeNotify" },
                        [
                          _c("span", { staticClass: "payRoll" }, [
                            _vm._v(
                              _vm._s(
                                _vm.infoForm.messageCategory === "调薪提醒"
                                  ? "调薪完成"
                                  : "工资条发送"
                              )
                            ),
                          ]),
                          _c("number", {
                            model: {
                              value: _vm.infoForm.remindTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.infoForm, "remindTime", $$v)
                              },
                              expression: "infoForm.remindTime",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "timeNum" },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "default-value": _vm.infoForm.infoTimeType,
                                  },
                                  model: {
                                    value: _vm.infoForm.remindTimeUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.infoForm,
                                        "remindTimeUnit",
                                        $$v
                                      )
                                    },
                                    expression: "infoForm.remindTimeUnit",
                                  },
                                },
                                _vm._l(_vm.timeTypeList, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(_vm._s(item.time))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "payRoll" }, [
                            _vm._v("后提醒"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "通知人", prop: "remindNotifiler" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { mode: "multiple", "token-separators": [","] },
                      model: {
                        value: _vm.infoForm.remindNotifiler,
                        callback: function ($$v) {
                          _vm.$set(_vm.infoForm, "remindNotifiler", $$v)
                        },
                        expression: "infoForm.remindNotifiler",
                      },
                    },
                    _vm._l(_vm.notifierList, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.person))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }